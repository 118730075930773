import {UserProfileTest1} from "./UserProfile";

export const ScholarshipGeneral = {
    id: 200,
    name: 'General Scholarship',
    description: 'Anyone can secure the bag.',
    amount: '200',
    slug: 'general-scholarship',
    deadline: '2020-02-17T04:59:00Z',
};

export const ScholarshipEngineering = {
    id: 100,
    owner: UserProfileTest1.user,
    name: 'Engineering Scholarship',
    description: 'Scholarship only for awesome engineering students',
    amount: '100',
    slug: 'engineering-scholarship',
    eligible_programs: ['Engineering'],
    deadline: '2020-02-17T04:59:00Z',
};

export const scholarshipsListMockData = [
    ScholarshipGeneral,
    ScholarshipEngineering
];


const SAMPLE_STUDENT_1 = {
    first_name: 'Jalen',
    last_name: 'Green',
    description: 'Jalen Romande Green is an American basketball player who attended Prolific Prep in Napa, California.' +
        ' A consensus five-star recruit, he is considered the best shooting guard in the 2021 class,' +
        ' with ESPN ranking him number one overall',
    major: 'Kinesiology',
    post_secondary_school: 'Duke',
    username: 'jalen',
    profile_pic_url: 'https://a.espncdn.com/combiner/i?img=/i/headshots/recruiting/ncb/players/190/230212.jpg&w=190&h=254',
    funding_amount: 25000,
    grades: {

        'Mathematics': 88,
        'Science': 85,
        'English': 89,
        'Animal Biology': 95,
        'Physical Education': 99,

    },
    work_experience:{
        'Duke Blue Devils': {
            'Location': 'Raleigh, North Carolina',
            'Role': 'Varsity Men\'s Basketball Player',
            'Duration': 'May 2019 - Present',
            'Description': "Lead scorer on the Duke Varsity Men's 'basketball team, lead them to NCAA March Madness" +
                "Final Four appearance."
                }
    },
    estimated_salary: Math.floor(Math.random() * (20001)) + 70000,
    salary_pct: 0.059,
    loan_duration: 6,
};
const SAMPLE_STUDENT_2 = {
    first_name: 'Laura',
    last_name: 'Deming',
    gender: 'Female',
    description: 'Laura Deming is an aspiring venture capitalist and biomedical engineer.' +
        ' Her work focuses on life extension, and using biological research to reduce or reverse the effects of aging.',
    major: 'Biomedical Engineering',
    post_secondary_school: 'Stanford',
    username: 'ldeming1',
    profile_pic_url: 'https://pbs.twimg.com/profile_images/1846498068/Professional_Photo_2_-_Small_400x400.jpg',
    funding_amount: 15000,
    grades: {

        'Physics': 85,
        'Biology': 96,
        'Chemistry': 79

    },
    work_experience:{'Sigmon Industry': {
        'Location': 'Los Angleles',
        'Role': 'Pharmaceutical lab assistant',
        'Duration': 'May 2019- August 2019',
        'Description': 'Lab assistant in a large pharmaceutical company. Prepared compounds and built reaction tables'
    }
},
    estimated_salary: Math.floor(Math.random() * (20001)) + 70000,
    salary_pct: .05,
    loan_duration: 4,
};

const SAMPLE_STUDENT_3 = {
    first_name: 'Harsimran',
    last_name: 'Singh',
    description: 'Harsimran is a mathematics student.' +
        ' His work as a TA for 2nd year linear algebra helped 300 kids pass one of the toughest classes in his university. ',
    major: 'Applied Math',
    post_secondary_school: 'Caltech',
    username: 'hsingh',
    profile_pic_url: 'https://i.pinimg.com/originals/96/30/8f/96308f496d20b0451483317c57d15b06.jpg',
    funding_amount: 11000,
    grades: {

        'Linear Algebra': 95,
        'Calculus': 98,
        'Statistics': 93

    },
    work_experience:{
        'FinaRight': {
            'Location': 'Toronto',
            'Role': 'Investment Banker intern',
            'Duration': 'May 2019 - August 2019',
            'Description': 'Investment Banker intern. Utilized excel to markup payroll that lead to increase in efficieny by 30%'
                },
                
                'AGDH Insurance Group': {
                    'Location': 'Markham',
                    'Role': 'Acturial analyst intern',
                    'Duration': 'May 2017- August 2017',
                    'Description': 'Analyzed acturial science and predictions. Built predictive models'
                }
            },
            estimated_salary: Math.floor(Math.random() * (20001)) + 115000,
            resume_link: "https://resumegenius.com/wp-content/uploads/2018/12/College-Resume-Example-Template.png",
            salary_pct: .02,
            loan_duration: 5,
         };
        

const SAMPLE_STUDENT_4 = {
    first_name: 'Preethi',
    last_name: 'Kasireddy',
    gender: 'Female',
    description: 'Pretthi is a medical school student.' +
        ' Her experiences volunteering at the Sick kids hospital in university motivates her' +
        ' to become a specialist in pediatric cancer.',
    major: 'Medicine',
    post_secondary_school: 'McMaster',
    username: 'jdevon',
    profile_pic_url: 'https://techcrunch.com/wp-content/uploads/2018/05/screenshot-2018-05-23-13-47-21.png',
    funding_amount: 55000,
    grades: {
        'Human Biology': 95,
        'Organic Chemistry': 91,
        'Ethics': 89
    },
    work_experience:{
        'McMaster student hospital': {
            'Location': 'Hamilton',
            'Role': 'Assistant nurse in training',
            'Duration': 'May 2019- August 2019',
            'Description': 'Treated patients under supervision of senior nurse'
        }
    },
    resume_link: "https://resumegenius.com/wp-content/uploads/2018/12/College-Resume-Example-Template.png",
    salary_pct: .06,
    estimated_salary: Math.floor(Math.random() * (20001)) + 130000,
    loan_duration: 7,
};
const SAMPLE_STUDENT_5 = {
    first_name: 'Hunter',
    last_name: 'Jackson',
    description: 'Hunter Jackson is finance student.' +
        ' At the age of 15 he started trading stocks and crypto in his spare time, leading him to pursue a BBA at one of the worlds top schools',
    major: 'Business administration',
    post_secondary_school: 'Harvard',
    username: 'hjackson',
    profile_pic_url: 'https://brocku.ca/brock-news/wp-content/uploads/2018/03/Ishan-Patel-1600x1066.jpg?x68208',
    funding_amount: 18000,
    grades: {
        'Mathematics': 95,
        'Science': 86,
        'English': 89
    },
    work_experience: {

        'Sigmon Industry': {
            'Location': 'Los Angeles',
            'Role': 'Accountant intern',
            'Duration': 'May 2019- August 2019',
            'Description': 'Bookeeper for large pharmaceutical company'
        }
    },
    estimated_salary: Math.floor(Math.random() * (20001)) + 90000,
    resume_link: "https://resumegenius.com/wp-content/uploads/2018/12/College-Resume-Example-Template.png",
    salary_pct: .029,
    loan_duration: 7,
};
const SAMPLE_STUDENT_6 = {
    first_name: 'Rachel',
    last_name: 'Zane',
    description: 'Rachel is currently studying law at Harvard Law School. ' +
        'Before law school, Rachel was a consultant at Mckinsey',
    major: 'Law',
    post_secondary_school: 'Harvard',
    username: 'rachzane',
    profile_pic_url: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/meghan-markle-brush-1529438482.jpg',
    funding_amount: 18000,
    gender: 'Female',
    grades: {
        'Contracts': 88,
        'Constitutional Law': 86,
        'Tort': 86,
        'English': 97
    },
    work_experience: {

        'Pearson Specter': {
            'Location': 'New York',
            'Role': 'Summer Articling Student',
            'Duration': 'May 2019- August 2019',
        },

        'McKinsey': {
            'Location': 'New York',
            'Role': 'Consultant',
            'Duration': '2016-2018',
        }
    },
    estimated_salary: Math.floor(Math.random() * (20001)) + 90000,
    resume_link: "https://resumegenius.com/wp-content/uploads/2018/12/College-Resume-Example-Template.png",
    salary_pct: .05,
    loan_duration: 4,
};
const SAMPLE_STUDENT_7 = {
    first_name: 'Vitalik',
    last_name: 'Buterin',
    description: 'Vitalik is a Russian-Canadian programmer and writer' +
        ' primarily known as a co-founder of Ethereum and as a co-founder of Bitcoin Magazine.[',
    major: 'Software Engineering',
    post_secondary_school: 'University of Waterloo',
    username: 'vbuterin',
    profile_pic_url: 'https://pbs.twimg.com/profile_images/977496875887558661/L86xyLF4_400x400.jpg',
    funding_amount: 12000,
    grades: {
        'Mathematics': 95,
        'Physics': 91,
        'Computer Science': 99,
        'Economics': 95,
    },
    work_experience: {

        'Google': {
            'Location': 'Mountain View, California',
            'Role': 'Software Engineering intern',
            'Duration': 'May 2019- August 2019',
            'Description': 'Worked on decentralized protocols and blockchain prototypes for Google Search'
        }
    },
    estimated_salary: Math.floor(Math.random() * (20001)) + 90000,
    resume_link: "https://resumegenius.com/wp-content/uploads/2018/12/College-Resume-Example-Template.png",
    salary_pct: .02,
    loan_duration: 7,
};
export const SAMPLE_STUDENTS = [ SAMPLE_STUDENT_1, SAMPLE_STUDENT_6,
    SAMPLE_STUDENT_2, SAMPLE_STUDENT_3,
    SAMPLE_STUDENT_4, SAMPLE_STUDENT_5, SAMPLE_STUDENT_7];

export function searchSampleStudents(usernameToSearch) {
    for (let i=0; i < SAMPLE_STUDENTS.length; i++) {
        if (SAMPLE_STUDENTS[i].username === usernameToSearch) {
            return SAMPLE_STUDENTS[i];
        }
    }
}
export function filterUsers(searchTerm) {

    if(!searchTerm) {
        return  SAMPLE_STUDENTS;
    }
    const filteredUsers = [];
    SAMPLE_STUDENTS.forEach(student => {
        for (let prop in student) {
            if (student.hasOwnProperty(prop) && typeof student[prop]==='string') {
                if(student[prop].toLowerCase().includes(searchTerm.toLowerCase())){
                    filteredUsers.push(student);
                    break;
                }
            }
        }
    });

    return filteredUsers;
}