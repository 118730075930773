import request from 'axios';
import axios from 'axios';
import Environment from './Environment'
import GoogleAnalytics from "react-ga";
import {FirebaseFirestore} from "./FirebaseService";
import {searchSampleStudents} from "../models/Student";
class UserProfileAPI {

    static userProfileEndPoint = `${Environment.apiUrl}/user-profiles`;
    static userEndPoint = `${Environment.apiUrl}/users`;
    static bankServiceEndpoint = Environment.bankServiceEndpoint;

    static getUsername = (username) => {

        return FirebaseFirestore
            .collection("users")
            .where("username", "==", username).limit(1)
            .get()
            .then((querySnapshot) => {
                console.log({username});
                console.log(querySnapshot);

                let data = querySnapshot.docs[0];
                if (!data) {
                    data = searchSampleStudents(username)
                }
                else {
                    data = data.data();
                }

                return Promise.resolve(data);
                })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
                return Promise.reject(error)
            });
    };

    static get = (userId) => {

        return FirebaseFirestore
            .collection("users")
            .doc(userId)
            .get()
            .then((docRef) => {
                if (docRef.exists) {
                    const data = docRef.data();
                    console.log("Document data:",data );
                    return Promise.resolve(data);
                } else {
                    return Promise.reject({message: 'User not found'})
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
                return Promise.reject(error)
            });
    };

    static getUserContent = (userId, contentType) => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${this.userProfileEndPoint}/${userId}/${contentType}/`,
        });

        return apiCompletionPromise;
    };

    static removeSavedScholarship = (userId, scholarshipId) => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${this.userProfileEndPoint}/${userId}/remove-saved-scholarship/?scholarship=${scholarshipId}/`,
        });

        return apiCompletionPromise;
    };

    static refreshScholarshipCache = (userId) => {

        const apiCompletionPromise = request({
            method: 'post',
            data: {},
            url: `${this.userProfileEndPoint}/${userId}/refresh-scholarship-cache/`,
        });
        return apiCompletionPromise;
    };

    static getRequestId = (loginId) => {

        const apiCompletionPromise = request({
            method: 'post',
            url: `${this.bankServiceEndpoint}/authorize`,
            data: {
                loginId,
                "MostRecentCached":true
            }
        });

        return apiCompletionPromise;
    };

    static getBankAccountSummary = (requestId) => {

        const apiCompletionPromise = request({
            method: 'post',
            url: `${this.bankServiceEndpoint}/account-summary`,
            data: {
                requestId,
            }
        });

        return apiCompletionPromise;
    };

    static createUser = async (registrationData) => {

        const registrationDataPost = registrationData;

       
        console.log({registrationDataPost});
        const apiCompletionPromise = request({
            method: 'post',
            data: registrationDataPost,
            url: `${this.userEndPoint}/`,
        });

        return apiCompletionPromise;
    };

    static update = (userProfileData, id) => {

        return FirebaseFirestore
            .collection("users")
            .doc(id)
            .set(userProfileData)
            .then(function() {
                return Promise.resolve({userProfileData});
            });
    };

    static patch = (data, id) => {

        const apiCompletionPromise = request({
            method: 'patch',
            data,
            url: `${this.userProfileEndPoint}/${id}/`,
        });

        return apiCompletionPromise;
    };

    static login = (loginCredentials) => {

        const apiCompletionPromise = request({
            method: 'post',
            data: loginCredentials,
            url: `${Environment.apiUrl}/login/`,
        });

        return apiCompletionPromise;
    };

    static getUserLocationData = (userProfile) => {

        const locationData = {};
        if (userProfile.city.length > 0) {
            locationData.city = userProfile.city[0].name;
            locationData.country = userProfile.city[0].country;
            locationData.province = userProfile.city[0].province;
        }
        return locationData;
    };

    static resetPassword = (username) => {

        const apiCompletionPromise = request({
            method: 'post',
            data: {username},
            url: `${this.userProfileEndPoint}/reset-password/`,
        });

        return apiCompletionPromise;
    };

    static verifyResetPassword = (username, token, password) => {

        const apiCompletionPromise = request({
            method: 'post',
            data: {username, token, password},
            url: `${this.userProfileEndPoint}/verify-reset-password/`,
        });

        return apiCompletionPromise;
    };

    static verifyToken = (username, token) => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${this.userProfileEndPoint}/verify-token/?username=${username}&token=${token}`,
        });

        return apiCompletionPromise;
    };

    static authenticateRequests = (userId) => {

        if(!this.isLoggedIn(userId)) {
            return false;
        }

        GoogleAnalytics.set({userId});
        localStorage.setItem('userId', userId);
        return true;
    };

    static isLoggedIn(userId=null) {

        if(!userId) {
            userId = localStorage.getItem('userId');
        }

        if(!userId || userId === "undefined" ) {
            delete axios.defaults.headers.common['Authorization'];
            return false;
        }

        return true;

    }

    static logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        delete axios.defaults.headers.common['Authorization'];
    };


}

export default UserProfileAPI;