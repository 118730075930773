import { MAJORS_LIST, SCHOOLS_LIST} from "./ConstantsForm";
import PropTypes from "prop-types";

export const UserProfileTest1 = {
    username: 'cbarkley',
    first_name: 'Charles',
    last_name: 'Barkley',
    major: 'Basketball',
    id: 1,
    user: 1,
    is_atila_admin: false,
    eligible_programs: [],
    saved_scholarships: [],
};

export const userProfileFormConfig = [
    {
        keyName: 'first_name',
    },
    {
        keyName: 'last_name',
    },
    {
        keyName: 'email',
        type: 'email'
    },
    {
        keyName: 'post_secondary_school',
        type: 'autocomplete_single',
        suggestions: SCHOOLS_LIST,
    },
    {
        keyName: 'major',
        type: 'autocomplete_single',
        suggestions: MAJORS_LIST,
    },
   
];
export const DEFAULT_PROFILE_PIC_URL = 'https://firebasestorage.googleapis.com/v0/b/atila-7.appspot.com/o/user-profiles%2Fgeneral-data%2Fdefault-profile-pic.png?alt=media&token=455c59f7-3a05-43f1-a79e-89abff1eae57';
