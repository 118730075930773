import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCCTPKxdKtncfL6IPKfXmb0HY1BX7viL6o",
    authDomain: "prosper-capital-7.firebaseapp.com",
    databaseURL: "https://prosper-capital-7.firebaseio.com",
    projectId: "prosper-capital-7",
    storageBucket: "prosper-capital-7.appspot.com",
    messagingSenderId: "319850871809",
    appId: "1:319850871809:web:bace86f50424bddd069400",
    measurementId: "G-P0CPQ3ZCTC"
};

const FirebaseApp = firebase.initializeApp(firebaseConfig);
export const FirebaseFirestore = FirebaseApp.firestore();
export const FirebaseAuth = FirebaseApp.auth();