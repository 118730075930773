import React from 'react';
import Banner from './Banner';
import 'antd/dist/antd.css';
import './LandingPage.scss';
import './Reponsive.scss';
import {connect} from "react-redux";
import HowItWorks from "./HowItWorks";
import MoreFeatures from "./MoreFeatures";

class LandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scholarshipsDueSoon: null,
            scholarshipsRecentlyAdded: null,
            scholarshipsForLocation: null,
            searchLocation: 'Ontario'
        }
    }
    


    render() {
        
        return (
            <div>
            <Banner/>
            <hr/>
            <HowItWorks/>
            <hr/>
            <MoreFeatures/>
            </div>
                 
        );
    }
}
const mapStateToProps = state => {
    return { userProfile: state.data.user.loggedInUserProfile };
};
export default connect(mapStateToProps)(LandingPage);

