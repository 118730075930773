import React from 'react';
import {Link} from "react-router-dom";
import studentNeedsMoneySchool from './assets/studentNeedsMoneySchool.png';
import investorGivesStudentMoney from './assets/investorGivesStudentMoney.png';
import studentRepaysInvestor from './assets/studentRepaysInvestor.png';

function HowItWorks() {

    return (
        <div className="container">
            <h1 className="col-sm-12 text-center">
                How Prosper Works
            </h1>
            <h2 className="col-sm-12 text-center">
                <Link to="/login">Login.</Link> Find Students. Apply </h2>
            <div className="offset-lg-1">
                <div className="row">
                    <div className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3>1. Student Needs Money For School
                        </h3>
                        <p>Akshay is a software engineering student at University of Waterloo
                        and he needs $20,000 for school.</p>
                    </div>
                    <div className="card shadow m-3 p-3 col-lg-7 col-sm-12">

                        <img src={studentNeedsMoneySchool} id="registration-gif"
                             alt="Atila Registration Walkthrough" title="Atila Registration Walkthrough"
                             className="landing-page-gif landing-page-gif-desktop" />
                    </div>
                </div>
                <div className="row">
                    <div className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3>2. Investor Gives Student Money
                        </h3>
                        <p>Sarah is a consultant at Mckinsey and gives Akshay $20,000.</p>
                    </div>
                    <div className="card shadow m-3 p-3 col-lg-7 col-sm-12">

                        <img src={investorGivesStudentMoney} id="create-profile-gif"
                             alt="Create Your Profile" title="Create Your Profile"
                             className="landing-page-gif landing-page-gif-mobile" />
                    </div>
                </div>
                <div className="row">
                    <div className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3>3. Student pays Back Investor
                        </h3>
                        <p>
                            After Akshay graduates, Akshay gives Sarah 4%* of his salary for next 5* years,
                        to a maximum of $23,000
                        </p>

                    </div>
                    <div className="card shadow m-3 p-3 col-lg-7 col-sm-12">

                        <img src={studentRepaysInvestor} id="view-scholarships-gif"
                             alt="Find Your Scholarships" title="Find Your Scholarships"
                             className="landing-page-gif landing-page-gif-mobile" />
                    </div>
                </div>

                <small>
                    * = Repayment terms vary based on student factors
                </small>
            </div>
        </div>
    );
}

export default HowItWorks;