import React from 'react';
import engineeringStudents from './assets/engineeringStudents.png';
import rachelProfile from './assets/rachelProfile.png';
import investmentPerformance from './assets/investmentPerformance.png';
import connectBankAccount from './assets/connectBankAccount.png';
import './MoreFeatures.scss';
function MoreFeatures() {

    return (
        <div  className="MoreFeatures container">
            <h1 className="col-sm-12 text-center">
                More Features
            </h1>

            <div className="offset-lg-1">
                <div  className="row">
                    <div  className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3 >1. Search by Students you want to fund
                            <span role="img" aria-label="clock emoji">
                                🕐
                            </span>
                        </h3>
                        <p>Find students you want to invest in by school, program, work experience etc.</p>
                    </div>
                    <div  className="card shadow m-3 p-3 col-lg-7 col-sm-12">
                        <img  alt="Get Notified when Scholarships are Due"
                              className="landing-page-gif landing-page-gif-mobile" id="scholarship-notifications-gif"
                              src={engineeringStudents} title="Get Notified when Scholarships are Due" />
                    </div>

                </div>
                <div className="row">
                    <div className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3>2. Learn About Student
                            <span role="img" aria-label="memo emoji">
                            📝
                            </span>
                        </h3>
                        <p>View details on student to learn more about them</p>
                    </div>
                    <div className="card shadow m-3 p-3 col-lg-7 col-sm-12">

                        <img src={rachelProfile} id="view-scholarships-gif"
                             alt="Find Your Scholarships" title="Find Your Scholarships"
                             className="landing-page-gif landing-page-gif-mobile" />
                    </div>
                </div>
                <div  className="row">
                    <div  className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3>
                            3. Invest in Student and Earn
                        </h3>
                        <p >Calclate estimated return from investing in student</p>
                    </div>
                    <div  className="card shadow m-3 p-3 col-lg-7 col-sm-12">
                        <img  alt="Get Notified BEFORE Scholarships are Due"
                              className="landing-page-gif landing-page-gif-mobile"
                              src={investmentPerformance} title="Get Notified when Scholarships are Due" />
                    </div>
                </div>
                <div  className="row">
                    <div  className="card shadow m-3 p-5 col-lg-4 col-sm-12">
                        <h3 >4. Easy Financial Integration
                        </h3>
                        <p >Connect your bank account to easily verify account and transfer student funds</p>
                    </div>
                    <div  className="card shadow m-3 p-3 col-lg-7 col-sm-12">

                        <img  alt="Read Other student's Essays"
                              className="landing-page-gif landing-page-gif-mobile" id="view-essays-gif"
                              src={connectBankAccount} title="Read Other student's Essays" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MoreFeatures;