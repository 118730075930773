import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import loadable from '@loadable/component';
import 'bootstrap/dist/css/bootstrap.css';
import {connect} from "react-redux";
import LandingPage from "./scenes/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import Loading from "./components/Loading";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./components/Navbar/Navbar";
import GoogleAnalyticsTracker from "./services/GoogleAnalyticsTracker";
import ScrollToTop from "./components/ScrollToTop";
import HighSchool from "./components/HighSchool";

const Student = loadable(() => import('./scenes/Students/Students'), {
    fallback: <Loading />,
})

;const Search = loadable(() => import('./scenes/Search/Search'), {
    fallback: <Loading />,
});
const UserProfile = loadable(() => import('./scenes/UserProfile/UserProfile'), {
    fallback: <Loading />,
});
const Team = loadable(() => import('./components/Team/Team'), {
    fallback: <Loading />,
});

const ContactUs = loadable(() => import('./components/ContactUs'), {
    fallback: <Loading />,
});
const Login = loadable(() => import('./components/Login'), {
    fallback: <Loading />,
});
const VerifyAccount = loadable(() => import('./components/VerifyAccount'), {
    fallback: <Loading />,
});
const Register = loadable(() => import('./components/Register'), {
    fallback: <Loading />,
});


function App(props) {
    const {
        isLoadingLoggedInUserProfile,
        isFinishedLoadingLoggedInUserProfile,
    } = props;

    return (
            <Router>
                <ScrollToTop />
                <div className="App">
                    <ToastContainer />
                    <Navbar />
                    {isLoadingLoggedInUserProfile &&
                    <Loading loaderType="beat" title="" style={{ width: 'auto' }}/>}
                    {isFinishedLoadingLoggedInUserProfile &&
                    <React.Fragment>
                        <Route exact path="/" component={GoogleAnalyticsTracker(LandingPage)} />
                       
                        <Route path="/search" component={GoogleAnalyticsTracker(Search)} />
                        <Route path="/students" component={GoogleAnalyticsTracker(Student)} />
                        <Route path="/login" component={GoogleAnalyticsTracker(Login)} />
                        <Route path="/verify" component={GoogleAnalyticsTracker(VerifyAccount)} />
                        <Route path="/register" component={GoogleAnalyticsTracker(Register)} />
                        <Route path="/profile" component={GoogleAnalyticsTracker(UserProfile)} />
                        <Route path="/high-school" component={GoogleAnalyticsTracker(HighSchool)} />
                        <Route path="/highschool" component={GoogleAnalyticsTracker(HighSchool)} />
                        <Route path="/team" component={GoogleAnalyticsTracker(Team)} />
                        <Route path="/contact" component={GoogleAnalyticsTracker(ContactUs)} />
                    </React.Fragment>
                    }
                    <Footer />
                </div>
            </Router>
    );
}

const mapStateToProps = state => {
    const { ui :{
        user : {
            isLoadingLoggedInUserProfile,
            isFinishedLoadingLoggedInUserProfile,
        }
    }
    } = state;

    return {
        isLoadingLoggedInUserProfile,
        isFinishedLoadingLoggedInUserProfile,
    }
};

export default connect(mapStateToProps)(App);
